:root
{
    --toolbar-icon: #80CBC4;
}

.ayah-card
{
    width: 100%;
    border-bottom: 1px solid #dedede;
    margin-bottom: 35px;
    box-sizing: border-box;
    position: relative;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s;
}

.ayah-card:last-child
{
    border-bottom: 0;
    margin-bottom: 0;
}

.ayah-toolbar
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #E0F2F1;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.ayah-number
{
    color: #fff;
    width: 35px;
    height: 35px;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--toolbar-icon);
}

.ayah-ar
{
    color: #757575;
    font-family: 'Saleem Quran', sans-serif;
    font-size: 30px;
    font-weight: normal;
    line-height: 50px;
    text-align: right;
}

.ayah-idn
{
    color: #757575;
    font-style: italic;
    padding-top: 15px;
    padding-bottom: 15px;
}

.action-wrapper
{
    display: flex;
    align-items: center;
}

.audio-button,
.tafsir-button
{
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    box-sizing: border-box;
    transition: 0.5s;
}

.audio-button
{
    fill: var(--toolbar-icon);
    margin-right: 5px;
}

.audio-button svg
{
    height: 18px;
}

.audio-button:hover,
.tafsir-button:hover
{
    cursor: pointer;
    opacity: 0.55;
}

.audio-button:focus,
.tafsir-button:focus
{
    outline: none;
}

.fa-pause
{
    display: none;
}

.tafsir-button
{
    fill: none;
    stroke: var(--toolbar-icon);
}

.tafsir-button svg
{
    height: 22px;
}