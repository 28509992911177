.header-background
{
    width: calc(var(--content-width) - 30px);
    background: linear-gradient(150deg, #C5E1A5, #009688);
    border-radius: 10px;
    margin: auto;
    margin-top: 105px;
    margin-bottom: 30px;
}

.header-illustration
{
    height: 250px;
    display: flex;
    align-items: center;
    background-size: 30%;
    background-image: url('../../assets/images/al-quran.png');
    background-repeat: no-repeat;
    background-position: bottom -50px right -20px;
    border-radius: 10px;
}

.header-text
{
    padding-left: 50px;
    box-sizing: border-box;
}

.header-text > *
{
    color: #fff;
}

.header-text h1
{
    font-size: 36px;
    font-weight: 600;
    line-height: 43px;
}

.header-text p
{
    font-size: 20px;
    line-height: 27px;
}

.search-bar
{
    width: calc(var(--content-width) - 30px);
    margin: auto;
    margin-bottom: 30px;
    box-sizing: border-box;
}