.surah-card
{
    width: calc(100% - 65px);
    height: 85px;
    display: flex;
    align-items: center;
    margin-left: 65px;
    border-radius: 7px;
    border-bottom: 2px solid #81C784;
    background-size: 200% 100%;
    background-image: linear-gradient(to right, #fff 50%, #E0F2F1 50%);
    box-shadow: 0 0 10px rgba(234, 234, 234, 0.8);
    margin-bottom: 15px;
    box-sizing: border-box;
    position: relative;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    transition: 0.5s;
}

.surah-card:last-child
{
    margin-bottom: 0;
}

.surah-card:hover
{
    background-position: -100% 0;
    cursor: pointer;
}

.surah-number-wrapper
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    fill: #4CAF50;
    position: absolute;
    left: -65px;
}

.surah-number-wrapper p
{
    position: absolute;
}

.surah-name-wrapper
{
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
}

.surah-name p
{
    color: #757575;
    font-weight: 500;
}

.surah-name span
{
    color: #999999;
    font-size: 14px;
    line-height: 21px;
}

.surah-name-ar
{
    color: #757575;
    font-family: 'Saleem Quran', sans-serif;
    font-size: 28px;
    font-weight: normal;
}