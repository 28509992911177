/*
 * Font Configuration
 * Copyright 2021 · Ahmad Rivaldy S
 */


/* 
 * --- Font Family: Saleem Quran
 * --- Font Styles: Regular
 */

@font-face
{
    font-family: 'Saleem Quran';
    src: url('../fonts/saleem-quran/saleem-quran.woff2') format('woff2'),
         url('../fonts/saleem-quran/saleem-quran.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}


/* 
 * --- Font Family: Rubik
 * --- Font Styles: Light 300, Light 300 Italic, Regular 400, Regular 400 Italic, Medium 500, Medium 500 Italic, Semi-bold 600, Semi-bold 600 Italic, Bold 700, Bold 700 Italic, Extra-bold 800, Extra-bold 800 Italic, Black 900, Black 900 Italic
 */
  
/* cyrillic-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXO61F3f.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

/* cyrillic */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXq61F3f.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXy61F3f.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXC61F3f.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnX661A.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXO61F3f.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXq61F3f.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXy61F3f.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXC61F3f.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnX661A.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXO61F3f.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXq61F3f.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXy61F3f.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXC61F3f.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnX661A.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXO61F3f.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXq61F3f.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXy61F3f.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXC61F3f.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnX661A.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXO61F3f.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXq61F3f.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXy61F3f.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXC61F3f.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnX661A.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXO61F3f.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXq61F3f.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXy61F3f.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXC61F3f.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnX661A.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXO61F3f.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXq61F3f.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXy61F3f.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnXC61F3f.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face
{
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWEBXyIfDnIV7nEnX661A.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nMrXyi0A.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nFrXyi0A.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nDrXyi0A.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nPrXyi0A.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nBrXw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nMrXyi0A.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nFrXyi0A.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nDrXyi0A.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nPrXyi0A.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nBrXw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nMrXyi0A.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nFrXyi0A.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nDrXyi0A.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nPrXyi0A.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nBrXw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nMrXyi0A.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nFrXyi0A.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nDrXyi0A.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nPrXyi0A.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nBrXw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nMrXyi0A.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nFrXyi0A.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nDrXyi0A.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nPrXyi0A.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nBrXw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nMrXyi0A.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nFrXyi0A.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nDrXyi0A.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nPrXyi0A.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nBrXw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nMrXyi0A.woff2') format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nFrXyi0A.woff2') format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nDrXyi0A.woff2') format('woff2');
    unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nPrXyi0A.woff2') format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face
{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('../fonts/rubik/v12/iJWKBXyIfDnIV7nBrXw.woff2') format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}