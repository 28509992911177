.tafsir-header
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #dedede;
    margin-bottom: 15px;
}

.tafsir-title
{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tafsir-title h3,
.tafsir-title p
{
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
}

.tafsir-back,
.tafsir-next
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 5px;
    background-color: #E0F2F1;
    fill: none;
    stroke: #80CBC4;
    box-sizing: border-box;
    transition: 0.3s;
}

.tafsir-back:hover,
.tafsir-next:hover
{
    cursor: pointer;
    opacity: 0.5;
}

.tafsir-back.disabled,
.tafsir-next.disabled
{
    background-color: #eaeaea;
    stroke: #ccc;
}

.tafsir-back.disabled:hover,
.tafsir-next.disabled:hover
{
    cursor: unset;
    opacity: unset;
}

.tafsir-back svg,
.tafsir-next svg
{
    width: 25px;
}

.tafsir-link
{
    color: #80CBC4;
    display: inline-block;
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 15px;
    transition: 0.3s;
}

.tafsir-link:hover
{
    text-decoration: underline;
}

.tafsir-card
{
    padding: 15px;
    line-height: 26px;
    border-radius: 7px;
    background-color: #f7f7f7;
    margin-bottom: 15px;
}

.pick-tafsir
{
    width: 250px;
    margin: auto;
    margin-bottom: 15px;
    box-sizing: border-box;
}

.pick-tafsir select,
.pick-tafsir select option
{
    color: #fff;
    font-size: 14px;
    line-height: 21px;
}

.pick-tafsir select
{
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 7px;
    background-color: #80CBC4;
}

.pick-tafsir select:focus
{
    outline: none;
    box-shadow: 0 0 10px rgba(178, 223, 219, 0.85);
}