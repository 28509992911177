/* 
 * Qur'an Web
 * Copyright 2021 · Ahmad Rivaldy S
 */


@import url('../assets/css/font-config.css');

/* General */
*
{
    color: #757575;
    font-family: 'Rubik', sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 23px;
    padding: 0;
    margin: 0;
}

:root
{
    --content-width: 1024px;
}

::-webkit-scrollbar
{
    width: 14px;
}

::-webkit-scrollbar-track
{
    border-radius: 7px;
    background-color: rgba(204, 204, 204, 0.5);
}

::-webkit-scrollbar-thumb
{
    background-color: rgba(204, 204, 204, 0.9);
    border-radius: 7px;
}

body
{
    background-color: #f8f8f8;
}

a
{
    text-decoration: none;
}

b
{
    font-weight: 500;
}

.content
{
    width: var(--content-width);
    min-height: calc(100vh - 200px);
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
    box-sizing: border-box;
}

.content-bg
{
    width: calc(var(--content-width) - 30px);
    min-height: calc(100vh - 210px);
    padding: 15px;
    margin: auto;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(234, 234, 234, 0.8);
    box-sizing: border-box;
}

.content-margin
{
    margin-top: 105px;
}

.menubar-wrapper
{
    height: 75px;
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 2;
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
    width: 100%;
    box-sizing: border-box;
}

.menubar-wrapper.scrolled
{
    box-shadow: 0 0 10px rgba(128, 128, 128, 0.3);
    transition: 300ms linear;
}

.container
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: var(--content-width);
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
    box-sizing: border-box;
}

.logo
{
    height: 45px;
    width: 142px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../assets/images/bitquran-logo-wide.png');
    margin-bottom: 10px;
}


.menubar,
.menubar-mobile
{
    list-style: none;
    overflow: hidden;
    margin-bottom: 0;
}

.menubar-mobile
{
    margin: 0 15px;
}

.menubar li
{
    float: left;
}

.menubar a,
.menubar-mobile a
{
    display: block;
}

.menubar a
{
    color: #757575;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    padding: 5px 20px;
    transition: 0.3s;
}

.menubar a:hover,
.menubar-mobile a:hover
{
    color: #4CAF50;
    cursor: pointer;
}

.menubar-mobile a
{
    font-size: 14px;
    line-height: 21px;
    padding: 10px 15px;
    border-bottom: 1px solid #f5f5f5;
}

.menubar-mobile a:hover
{
    background-color: #f5f5f5;
    border-radius: 8px;
}

.menu-icon
{
    display: none;
    width: 30px;
    height: 30px;
}

.menu-icon:hover
{
    cursor: pointer;
}

.menu-icon svg
{
    stroke: #c1c1c1;
}

.sidemenu
{
    display: none;
    width: 250px;
    height: 100%;
    background-color: #fff;
    margin-right: -250px;
    padding-top: 52px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 3;
    overflow-x: hidden;
    transition: margin-right 0.3s;
}

.sidemenu.show
{
    margin-right: 0;
}

.sidemenu .close-button
{
    width: 45px;
    height: 45px;
    position: absolute;
    top: 0;
    right: 0;
    color: #c1c1c1;
    font-size: 20px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-bottom-left-radius: 15px;
}

.sidemenu .close-button:focus
{
    outline: none;
}

.container-cover
{
    display: none;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transform: scale(1.1);
    z-index: 2;
    transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s;
}

.container-cover.show
{
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s, transform 0.25s;
}

.goto-top
{
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    background: linear-gradient(150deg, #C5E1A5, #009688);
    border-radius: 10px;
    position: fixed;
    bottom: 15px;
    right: 15px;
    visibility: hidden;
    opacity: 0;
    transition: 0.5s;
}

.goto-top.show
{
    visibility: visible;
    opacity: 1;
}

.goto-top:hover
{
    opacity: 0.8;
    cursor: pointer;
}

.goto-top svg
{
    width: 18px;
    stroke: #fff;
}

.footer-wrapper
{
    height: 75px;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    margin-top: 30px;
    border-top: 1px solid #eaeaea;
}

.footer-wrapper p,
.footer-wrapper a
{
    color: #999;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
}

.footer-wrapper a
{
    color: #4CAF50;
    transition: 0.3s;
}

.footer-wrapper a:hover
{
    opacity: 0.75;
}

.footer-wrapper svg
{
    fill: #999;
    width: 14px;
    margin-top: 2px;
    margin-left: 3px;
    margin-right: 3px;
}


/* Responsive Configuration */
@media (max-width: 769px)
{
    .menubar-wrapper,
    .container,
    .content
    {
        width: 100vw;
    }

    .content-bg,
    .header-background,
    .surah-header-background,
    .pick-ayah,
    .search-bar
    {
        width: calc(100vw - 30px) !important;
    }

    .pick-ayah,
    .search-bar
    {
        margin-bottom: 20px;
    }

    .pick-ayah select
    {
        border-radius: 5px;
    }
}

@media (max-width: 426px)
{
    .menubar-wrapper
    {
        height: 65px;
    }

    .logo
    {
        height: 35px;
        width: 110px;
        margin-bottom: 5px;
    }

    .content-bg
    {
        border-radius: 7px;
    }

    .content-margin
    {
        margin-top: 85px;
    }

    .menu-icon
    {
        display: block;
    }

    .menubar
    {
        display: none;
    }

    .sidemenu,
    .container-cover
    {
        display: block
    }

    .header-background
    {
        border-radius: 7px;
        margin-top: 85px;
        margin-bottom: 20px;
    }

    .header-illustration
    {
        height: 150px;
        background-size: 35%;
        background-position: bottom -25px right -10px;
        border-radius: 7px;
    }

    .header-text
    {
        padding-left: 25px;
    }

    .header-text h1
    {
        font-size: 22px;
        line-height: 29px;
    }

    .header-text p,
    .surah-number-wrapper p
    {
        font-size: 14px;
        line-height: 21px;
    }

    .surah-header-background
    {
        min-height: 230px;
        border-radius: 7px;
        margin-top: 85px;
        margin-bottom: 35px;
    }

    .surah-header-illustration
    {
        background-size: 45%;
        background-position: bottom -25px right 25px;
        border-radius: 7px;
    }

    .arabic-name
    {
        font-size: 26px !important;
        line-height: 33px !important;
    }

    .pre-bismillah
    {
        font-size: 26px;
        line-height: 33px;
        margin-top: 20px;
    }

    .pre-bismillah-btn-play
    {
        width: 30px;
        height: 30px;
        margin-top: 15px;
    }

    .surah-header-text h1
    {
        font-size: 22px;
        line-height: 29px;
    }

    .surah-header-text hr
    {
        margin-top: 7px;
        margin-bottom: 7px;
    }

    .surah-header-text h5,
    .surah-header-text > p
    {
        font-size: 14px;
        line-height: 21px;
    }

    .surah-card
    {
        width: calc(100% - 55px);
        height: 70px;
        margin-left: 55px;
        border-radius: 5px;
    }

    .surah-number-wrapper
    {
        width: 40px;
        height: 40px;
        left: -55px;
    }

    .surah-name-wrapper
    {
        padding: 0 20px;
    }

    .pick-ayah select,
    .pick-ayah select option,
    .input,
    .surah-name p
    {
        font-size: 14px;
        line-height: 21px;
    }

    .surah-name span
    {
        font-size: 12px;
        line-height: 19px;
    }

    .surah-name-ar
    {
        font-size: 26px;
    }

    .ayah-card
    {
        margin-bottom: 25px;
    }

    .ayah-number
    {
        width: 30px;
        height: 30px;
        font-size: 12px;
        line-height: 19px;
    }

    .ayah-ar
    {
        font-size: 26px;
        line-height: 46px;
    }

    .ayah-idn
    {
        font-size: 14px;
        line-height: 21px;
    }

    .audio-button svg
    {
        height: 15px;
    }

    .tafsir-button svg
    {
        height: 19px;
    }

    .tafsir-title h3,
    .tafsir-title p
    {
        font-size: 16px;
        line-height: 23px;
    }

    .tafsir-title span
    {
        font-size: 14px;
        line-height: 21px;
    }

    .pick-tafsir
    {
        width: 100%;
    }

    .pick-tafsir select,
    .pick-tafsir select option,
    .tafsir-link
    {
        font-size: 12px;
        line-height: 19px;
    }

    .tafsir-card
    {
        font-size: 14px;
        line-height: 24px;
        border-radius: 5px;
    }

    .card-about h3
    {
        font-size: 18px;
        line-height: 25px;
    }

    .card-about p,
    .card-about p i,
    .card-about p a,
    .card-about p b
    {
        font-size: 14px;
        line-height: 24px;
    }

    .footer-wrapper p,
    .footer-wrapper a
    {
        font-size: 12px;
        line-height: 19px;
    }

    .footer-wrapper svg
    {
        width: 12px;
    }
}