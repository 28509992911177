.input
{
    width: 100%;
    border: 1px solid #80CBC4;
    padding: 12px 15px;
    border-radius: 8px;
    background-color: #E0F2F1;
    outline: none;
    box-sizing: border-box;
}

.input:focus
{
    box-shadow: 0 0 8px rgba(178, 223, 219, 0.85);
}