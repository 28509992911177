.surah-header-background
{
    width: calc(var(--content-width) - 30px);
    min-height: 300px;
    background: linear-gradient(150deg, #C5E1A5, #009688);
    border-radius: 10px;
    margin: auto;
    margin-top: 105px;
    margin-bottom: 50px;
    position: relative;
    box-shadow: 10px 25px 30px rgba(117, 117, 117, 0.45);
}

.surah-header-illustration
{
    width: 100%;
    height: 100%;
    background-size: 35%;
    background-image: url('../../assets/images/al-quran.png');
    background-repeat: no-repeat;
    background-position: bottom -50px right 50px;
    opacity: 0.35;
    border-radius: 10px;
    position: absolute;
}

.surah-header-text
{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 15px;
    margin: auto;
    position: absolute;
}

.surah-header-text > *
{
    color: #fff;
}

.surah-header-text h1
{
    font-size: 28px;
    font-weight: 500;
    line-height: 35px;
}

.surah-header-text hr
{
    border: 0;
    height: 1px;
    width: 100%;
    background-image: linear-gradient(to right, transparent, #fff, transparent);
    margin-top: 15px;
    margin-bottom: 15px;
}

.surah-header-text > p
{
    font-size: 18px;
    line-height: 25px;
}

.arabic-name,
.pre-bismillah
{
    font-family: 'Saleem Quran', sans-serif;
    font-weight: normal;
}

.arabic-name
{
    font-size: 28px !important;
    line-height: 35px !important;
    margin-bottom: 10px;
}

.pre-bismillah-wrapper
{
    display: flex;
    align-items: center;
}

.pre-bismillah
{
    color: #fff;
    font-size: 42px;
    line-height: 49px;
    margin-top: 35px;
    margin-bottom: 15px;
}

.pre-bismillah-btn-play
{
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    fill: salmon;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 30px;
    margin-right: 15px;
    transition: 0.5s;
}

.pre-bismillah-btn-play:hover
{
    cursor: pointer;
}

.pre-bismillah-btn-play:focus
{
    outline: none;
}

.fa-pause
{
    display: none;
}

.pick-ayah
{
    width: calc(var(--content-width) - 30px);
    margin: auto;
    margin-bottom: 30px;
    box-sizing: border-box;
}

.pick-ayah select,
.pick-ayah select option
{
    color: #fff;
}

.pick-ayah select
{
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 7px;
    background-color: #80CBC4;
}

.pick-ayah select:focus
{
    outline: none;
    box-shadow: 0 0 10px rgba(178, 223, 219, 0.85);
}