.card-about
{
    padding: 15px;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 20px;
}

.card-about:last-child
{
    border-bottom: 0;
}

.card-about h3
{
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    margin-bottom: 10px;
}

.card-about ul
{
    padding-left: 30px;
}

.card-about p,
.card-about p i,
.card-about p a,
.card-about p b,
.card-about ul li
.card-about ul li i
{
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 10px;
}

.card-about p a b
{
    color: #4CAF50;
    transition: 0.3s;
}

.card-about p a b:hover
{
    opacity: 0.5;
}

.card-about p b,
.label
{
    font-weight: 400;
}